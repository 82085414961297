/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BackofficeMenu from 'components/BackofficeMenu';
import moment from 'moment';
import api from 'api';
import { Alert } from '@material-ui/lab';
import { Tooltip, IconButton, Checkbox } from '@material-ui/core';
import { toast } from 'react-toastify';
import { IoCopyOutline } from 'react-icons/io5';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { Header, DataTable, Button } from 'components';
import {
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  PhotosArea,
  PhotosItem,
  Card,
} from './styles';
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation } from 'react-icons/bs';
import { formatCPF, formatPlate, formatCurrency, formatCNPJ, formatPhone } from 'react-data-formatter';
import { success, loadingColor, error, warn, primary, darkGray } from 'styles/colorProvider';
import { dataColumns } from './helper';
import { ConfirmDialog } from 'components';
import { getCardFlag } from 'utils/creditCard';
import { ADJUST_VALUE, IOF } from 'utils/general';
import { cpf } from 'cpf-cnpj-validator';
import { addMonthsByModality, translatePolicyModality } from 'utils/policyModality';

export const translateInspectionStatus = {
  approved: 'Aprovado',
  rejected: 'Rejeitada',
  pending: 'Pendente',
  pending_approval: 'Aguardando avaliação de fotos',
};

function PolicyDetails({ history, location }) {
  const policyId = parseInt(location.pathname.split('/detalhes-da-apolice/')[1]);

  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmClaimPolicy, setConfirmClaimPolicy] = useState(false);
  const [clientActiveCard, setClientActiveCard] = useState();
  const [policyInfo, setPolicyInfo] = useState({
    adjustmentFactor: '70%',
    adjustmentFactorValue: 70,
    userBetween18and25Years: false,
    franchiseValue: 'Normal-Obrigatória 10%',
  });
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const loadClientActiveCard = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: `/policy-active-card/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      setClientActiveCard(response.data);
    } catch (error) {}
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await api({
        method: 'GET',
        url: `/company-policy/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      let valueService = 0;
      let valueCoverage = 0;
      let hasServices = false;
      let hasCoverages = false;
      for (const item of response.data?.arrayUnique || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
      if (response.data?.products) {
        for (const item of response.data?.products || []) {
          if (item.isService) {
            hasServices = true;
            valueService += Number(item.value);
          } else {
            hasCoverages = true;
            valueCoverage += Number(item.value);
          }
        }
      }
      if (response.data?.coverages) {
        for (const item of response.data?.coverages || []) {
          if (item.isService) {
            hasServices = true;
            valueService += Number(item.value);
          } else {
            hasCoverages = true;
            valueCoverage += Number(item.value);
          }
        }
      }

      if (response.data?.coverages?.length) {
        hasCoverages = true;
        setValueCoverage(response?.data?.value_final);

        if (response?.data) {
          response.data.TB_PROPOSAL.iof = response?.data?.value_final * IOF;
        }
      } else {
        setValueCoverage(valueCoverage);
      }

      setPolicyData(response.data);

      setHasCoverages(hasCoverages);
      setHasServices(hasServices);
      setLoading(false);
      setValueService(valueService);
    } catch (error) {
      history.push('/nao-autorizado');
    }
  };

  const actionDataColumns = [
    ...dataColumns,
    {
      name: 'Link',
      selector: (row) => (
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(`https://v.vistei.app/${row.inspection_code}`);
            toast.success('Link para vistoria copiado com sucesso!', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            });
          }}
        >
          <IoCopyOutline size={15} />
        </IconButton>
      ),
      width: '15%',
      sortable: true,
    },
  ];

  const handleCreateClaimPolicy = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'post',
        url: `/claim-policy-mark`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: policyId,
        },
      });
      setConfirmClaimPolicy(resp);
      setLoading(false);
      toast.success('Apólice com sinistro!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmClaimPolicy(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      setConfirmClaimPolicy(false);
    }
  };

  const handleChangeAutomaticCharge = async (updatedPolicyData) => {
    try {
      await api({
        method: 'PATCH',
        url: `/automatic-charge`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_policy: policyId,
          automatic_charge: updatedPolicyData?.automatic_charge,
        },
        json: true,
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const renderConfirmClaimPolicy = () => (
    <ConfirmDialog
      open={confirmClaimPolicy}
      onCancel={() => setConfirmClaimPolicy(false)}
      content="Ao confirmar a apólice não será renovada automáticamente"
      title="Apólice com Sinistro"
      onConfirm={handleCreateClaimPolicy}
      loading={loading}
    />
  );

  useEffect(() => {
    loadClientActiveCard();
    loadData();
  }, []);

  const iof = 0.078;

  useEffect(() => {
    // LMI LOGIC
    const auctionOrRemarked = policyData?.vehicle?.auction || policyData?.vehicle?.remarked;
    // Starts with 70% <- Auction or Remarked
    let adjustmentFactor = '70%';
    let adjustmentFactorValue = 70;

    if (!auctionOrRemarked) {
      // If has valid LMI or 100%
      adjustmentFactor = policyData?.adjustmentFactor || '100%';
      adjustmentFactorValue = policyData?.adjustmentFactorValue || 100;
    }

    // Franchise Logic
    const showFranchiseValue = policyData?.show_deductible_pricing;

    const franchiseValue = policyData?.deductible_pricing;
    // Apólices antigas
    const oldFranchiseValue = policyData.coverages?.find((c) => c?.name?.includes('Franquia'))?.name_complete;

    // User Between 18 and 25 years
    const userBetween18and25Years = policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old;

    setPolicyInfo({
      ...policyInfo,
      adjustmentFactor,
      adjustmentFactorValue,
      userBetween18and25Years,
      franchiseValue:
        showFranchiseValue && franchiseValue ? franchiseValue : oldFranchiseValue ? oldFranchiseValue : 'Normal-Obrigatória 10%',
    });
  }, [policyData]);
  const isCpf = cpf.isValid(policyData?.user?.cpf);

  return (
    <>
      {renderConfirmClaimPolicy()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <BackofficeMenu policyId={policyId} policyData={policyData} />
            <Title>DETALHES DA APÓLICE</Title>
            {policyData?.status === 'active' && policyData?.not_renew === true && (
              <Alert style={{ marginBottom: 20 }} severity="warning">
                Cancelamento solicitado - Sua vigência é válida até o próximo vencimento{' '}
                {moment(policyData.next_due).format('DD/MM/YYYY')}
              </Alert>
            )}
            <PolicyInformationArea>
              <ItemPolicy>
                {policyData?.status === 'active' && policyData?.requested_cancellation === false && (
                  <Subtitle style={{ color: success, textTransform: 'uppercase', marginBottom: 10 }}>
                    <BsShieldFillCheck size={16} style={{ marginRight: 5 }} /> Você está seguro!
                  </Subtitle>
                )}
                {policyData?.status === 'active' &&
                  policyData?.requested_cancellation === true &&
                  policyData?.accepted_cancellation === true && (
                    <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                      <BsShieldFillCheck size={16} style={{ marginRight: 5 }} /> Você está seguro!
                    </Subtitle>
                  )}

                {policyData?.status === 'overdue' && (
                  <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                    <BsShieldFillExclamation size={16} style={{ marginRight: 5 }} /> Aguardando pagamento!
                  </Subtitle>
                )}

                {policyData?.requested_cancellation === true &&
                  (policyData?.accepted_cancellation === null || policyData?.accepted_cancellation === false) && (
                    <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                      <BsShieldFillExclamation size={16} style={{ marginRight: 5 }} /> Aguardando cancelamento!
                    </Subtitle>
                  )}

                {policyData?.status === 'canceled' && (
                  <Subtitle style={{ color: error, textTransform: 'uppercase', marginBottom: 10 }}>
                    <BsShieldFillX size={16} style={{ marginRight: 5 }} /> Você não está seguro!
                  </Subtitle>
                )}

                {policyData?.claim_notice === true && (
                  <>
                    <Text>
                      Apólice com sinistro: <span>Sim</span>
                    </Text>
                  </>
                )}

                <Text>
                  Apólice: <span>{policyData?.policy_number}</span>
                </Text>
                {hasCoverages && (
                  <Text>
                    Valor Coberturas:{' '}
                    <span>
                      {formatCurrency(
                        (policyData?.isHinova ? policyData?.valueFinal : valueCoverage) - policyData?.TB_PROPOSAL?.iof,
                      )}
                    </span>
                  </Text>
                )}
                {hasCoverages && (
                  <Text>
                    Valor IOF sobre Coberturas: <span>{`${formatCurrency(policyData?.TB_PROPOSAL?.iof || 0)}`}</span>
                  </Text>
                )}
                {hasServices && (
                  <Text>
                    Valor Serviços: <span>{`${formatCurrency(valueService)}`}</span>
                  </Text>
                )}
                <Text>
                  Valor Total do Seguros: <span>{`${formatCurrency(policyData?.valueFinal || 0)}`}</span>
                </Text>
                <Text>
                  Fator de ajuste LMI: <span>{policyInfo?.adjustmentFactor}</span>
                </Text>
                <Text>
                  Franquia: <span>{policyInfo?.franchiseValue}</span>
                </Text>

                {/*
                {policyData?.status !== 'canceled' && policyData?.claim_notice !== true && (
                  <>
                    <Button className="buttonCancelProposal" onClick={() => setConfirmClaimPolicy(true)}>
                      Apólice com sinistro
                    </Button>
                  </>
                )} */}
              </ItemPolicy>

              <ItemPolicy>
                {<Subtitle>Apólice gerada em:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.created_at).format('DD/MM/YYYY')}</Text>

                <Subtitle>Modalidade de contratação:</Subtitle>
                <Text style={{ marginBottom: '1.3rem' }}>{translatePolicyModality(policyData?.modality)}</Text>

                <Subtitle>Inicio da vigência apólice:</Subtitle>
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.created_at).format('DD/MM/YYYY')}</Text>

                <Subtitle>Fim da vigência apólice:</Subtitle>
                <Text style={{ marginBottom: '1.3rem' }}>
                  {addMonthsByModality(policyData?.created_at, policyData?.modality).format('DD/MM/YYYY')}
                </Text>

                {policyData?.status !== 'active' ? (
                  <Subtitle>Vencido em</Subtitle>
                ) : (
                  <Subtitle>Inicio da vigência cobertura:</Subtitle>
                )}
                <Text style={{ marginBottom: '1.3rem' }}>
                  {moment
                    .utc(policyData?.expiration_date)
                    .subtract(1, 'months')
                    .set('date', moment.utc(policyData?.created_at).date())
                    .format('DD/MM/YYYY')}
                </Text>

                {policyData?.status !== 'active' ? (
                  <Subtitle>Vencido em</Subtitle>
                ) : (
                  <Subtitle>Fim da vigência cobertura:</Subtitle>
                )}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.expiration_date).format('DD/MM/YYYY')}</Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Próximo vencimento:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.next_due).format('DD/MM/YYYY')}</Text>

                <Subtitle>Cobrança automática:</Subtitle>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                  <Text style={{ margin: '0 1px 0 0' }}>{policyData?.automatic_charge ? 'Ativa' : 'Inativa'}</Text>
                </div>

                {policyData?.automatic_charge && clientActiveCard && (
                  <Card>
                    <Text>
                      Cartão:
                      <span> **** **** **** {clientActiveCard?.last_four_digits}</span>
                    </Text>
                    <div>{getCardFlag(clientActiveCard?.last_four_digits, clientActiveCard?.brand).image}</div>
                  </Card>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do segurado</Subtitle>
                <Text>
                  Nome do segurado: <span>{policyData?.user?.name}</span>
                </Text>
                <Text>
                  Nome social: <span>{policyData?.user?.social_name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento: <span>{moment(policyData?.user?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</span>
                  </Text>
                )}
                <Text>
                  Telefone: <span>{formatPhone(policyData?.user?.phone)}</span>
                </Text>
                <Text>
                  E-mail: <span>{formatCPF(policyData?.user?.email)}</span>
                </Text>
              </ItemPolicy>

              {policyData?.TB_PARTNER_STIPULATING && (
                <ItemPolicy>
                  <Subtitle>Informações do estipulante</Subtitle>
                  <Text>
                    Nome do estipulante: <span>{policyData?.TB_PARTNER_STIPULATING?.name}</span>
                  </Text>
                  <Text>
                    CNPJ: <span>{formatCNPJ(policyData?.TB_PARTNER_STIPULATING?.document)}</span>
                  </Text>
                  <Text>
                    Endereço: <span>{policyData?.TB_PARTNER_STIPULATING?.address}</span>
                  </Text>
                  <Text>
                    CEP: <span>{policyData?.TB_PARTNER_STIPULATING?.zipcode}</span>
                  </Text>
                </ItemPolicy>
              )}

              <ItemPolicy>
                <Subtitle>Informações do condutor principal</Subtitle>
                <Text>
                  Nome do segurado: <span>{policyData?.TB_PROPOSAL?.maindriver_name || policyData?.user?.name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.TB_PROPOSAL?.maindriver_document || policyData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento:{' '}
                    <span>
                      {moment(policyData?.TB_PROPOSAL?.maindriver_birthdate || policyData?.user?.birthdate)
                        .utcOffset(0)
                        .format('DD/MM/YYYY')}
                    </span>
                  </Text>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do veículo</Subtitle>
                <Text>
                  Marca e modelo: <span>{`${policyData?.vehicle?.brand}/ ${policyData?.vehicle?.model}`}</span>
                </Text>
                <Text>
                  Placa:{' '}
                  <span>{policyData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policyData?.vehicle?.plate)}</span>
                </Text>
                <Text>
                  Uso do veículo: <span>{policyData?.vehicle?.vehicle_use?.name}</span>
                </Text>
                <Text>
                  Ano e modelo: <span>{`${policyData?.vehicle?.manufacture_year} / ${policyData?.vehicle?.year_model}`}</span>
                </Text>
                <Text>
                  Chassi: <span>{formatPlate(policyData?.vehicle?.chassi)}</span>
                </Text>
                <Text>
                  Valor FIPE: <span>{formatCurrency(policyData?.vehicle?.value || 0)}</span>
                </Text>
                <Text>
                  LMI {policyInfo?.adjustmentFactor}:{' '}
                  <span>{formatCurrency(policyData?.vehicle?.value * (policyInfo?.adjustmentFactorValue / 100) || 0)}</span>
                </Text>
                <Text>
                  Veículo de leilão: <span>{policyData?.vehicle?.auction === 1 ? 'SIM' : 'NÃO'}</span>
                </Text>

                <Text>
                  Chassis Remarcado/Veiculo Recuperado de Sinistro: <span>{policyData?.vehicle?.remarked ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  GNV: <span>{policyData?.vehicle?.gnv ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  Condutor entre 18 e 25 anos: <span>{policyData?.userBetween18and25Years ? 'SIM' : 'NÃO'}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                {hasCoverages && <Subtitle>Coberturas</Subtitle>}
                {policyData?.products?.length &&
                  policyData?.products
                    .filter((coverage) => coverage.isService === false)
                    .map((coverage) => <Text>• {coverage.name} </Text>)}
                {policyData?.coverages?.length &&
                  policyData?.coverages
                    .filter((product) => product.isService === false)
                    .map((product) => <Text>• {product.name} </Text>)}
                {policyData?.arrayUnique?.length &&
                  policyData?.arrayUnique
                    .filter((array) => array.isService === false)
                    .map((array) => <Text>• {array.name} </Text>)}
                {hasServices && <Subtitle style={{ paddingTop: '10px' }}>Serviços</Subtitle>}
                {policyData?.products?.length &&
                  policyData?.products
                    .filter((coverage) => coverage.isService === true)
                    .map((coverage) => <Text>• {coverage.name} </Text>)}
                {policyData?.coverages?.length &&
                  policyData?.coverages
                    .filter((product) => product.isService === true)
                    .map((product) => <Text>• {product.name} </Text>)}
                {policyData?.arrayUnique?.length &&
                  policyData?.arrayUnique
                    .filter((array) => array.isService === true)
                    .map((array) => <Text>• {array.name} </Text>)}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do Representante</Subtitle>
                <Text>
                  Nome do agente de vendas: <span>{policyData?.salesAgent?.name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.salesAgent?.cpf)}</span>
                </Text>
                <Text>
                  Telefone: <span>{formatPhone(policyData?.salesAgent?.phone)}</span>
                </Text>
                <Text>
                  E-mail: <span>{formatCPF(policyData?.salesAgent?.email)}</span>
                </Text>
              </ItemPolicy>

              {/* ENDEREÇO */}
              <ItemPolicy>
                <Subtitle>Endereço do segurado</Subtitle>

                <Text>{/* CEP: <span>{formatZipCode(policyData?.address?.zip_code)}</span> */}</Text>
                <Text>
                  Endereço: <span>{policyData?.TB_ADDRESS?.street}</span>
                </Text>
                <Text>
                  Número:{' '}
                  <span>
                    {policyData?.TB_ADDRESS?.number} {policyData?.address?.complement}
                  </span>
                </Text>
                <Text>
                  Bairro: <span>{policyData?.TB_ADDRESS?.district}</span>
                </Text>
                <Text>
                  Cidade/UF:{' '}
                  <span>
                    {policyData?.TB_ADDRESS?.city}/{policyData?.TB_ADDRESS?.state}
                  </span>
                </Text>
                <Text>
                  Complemento: <span>{policyData?.TB_ADDRESS?.complement}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Vistoria</Subtitle>
                <Text>
                  Código: <span>{policyData?.inspection?.inspection_code || 'N/A'}</span>
                </Text>
                <Text>
                  Status: <span>{translateInspectionStatus[policyData?.inspection?.status] || 'N/A'}</span>
                </Text>
                <Text>
                  Solicitada em:{' '}
                  <span>
                    {policyData?.inspection?.created_at ? moment(policyData?.inspection?.created_at).format('DD/MM/YYYY') : 'N/A'}
                  </span>
                </Text>
                <Text>
                  Finalizada em:{' '}
                  <span>
                    {policyData?.inspection?.result_date
                      ? moment(policyData?.inspection?.result_date).format('DD/MM/YYYY')
                      : 'N/A'}
                  </span>
                </Text>
              </ItemPolicy>
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default PolicyDetails;
