/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { loadingColor, white } from 'styles/colorProvider';
import {
  Container,
  LoadingArea,
  ButtonArea,
  Printable,
  PrintStyles,
  Header,
  HrHeader,
  Footer,
  FooterRow,
  LogoIcon,
  Row,
  SectionContainer,
  SectionTitle,
  RowLabel,
  RowText,
  Column,
  SectionSmall,
  FooterText,
  ContainerBetween,
  ColumnBetween,
  RowBetween,
} from './styles';
import { formatCPF, formatCNPJ, formatPlate, formatPhone, formatZipCode, formatCurrency } from 'react-data-formatter';
import { Button } from 'components';
import { BsPrinter } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { formatDocument } from 'react-data-formatter';
import { cpf } from 'cpf-cnpj-validator';
import { formatProposalStatus } from 'utils/general';
import { FidelityDiscountLabelByModality, translatePolicyModality } from 'utils/policyModality';

function BackofficePrintQuotation({ location, history }) {
  const proposalId = parseInt(location.pathname.split('/')[3]);
  const [proposalData, setProposalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const shouldDisplayModalityDiscountObservation =
    proposalData?.modality_discount_type === 'fidelity' &&
    !!proposalData?.modality_discount &&
    proposalData?.modality !== 'monthly';
  const loadData = async () => {
    const proposalNumber = {
      method: 'GET',
      url: `/proposal/${proposalId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);

    const response = await api(proposalNumber);

    let valueService = 0;
    let valueCoverage = 0;
    let hasServices = false;
    let hasCoverages = false;
    for (const item of response.data?.arrayUnique || []) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }
    if (response.data?.products) {
      for (const item of response.data?.products || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }
    if (response.data?.coverages) {
      for (const item of response.data?.coverages || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }

    if (response?.data?.coverages?.length) {
      hasCoverages = true;
      setValueCoverage(response?.data?.price?.total_premium);
      if (response?.data) response.data.iof = response?.data?.price?.iof;
    } else {
      setValueCoverage(valueCoverage);
    }

    setProposalData(response.data);

    setHasCoverages(hasCoverages);
    setHasServices(hasServices);
    setLoading(false);
    setValueService(valueService);
  };

  useEffect(() => loadData(), []);

  const isCpf = cpf.isValid(proposalData?.user?.cpf);

  return (
    <>
      <PrintStyles />
      {!loading && (
        <ButtonArea>
          <Button style={{ color: white }} height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <Header>
              <div className="titleArea">
                <h2>
                  Olá, <b>{proposalData?.user?.name?.toUpperCase()}</b>
                </h2>
                <h2>
                  Para você que está sempre em movimento,
                  <br />
                  aqui estão todos os detalhes do seu Seguro Auto.
                </h2>
              </div>
              <div className="logoArea">
                <img src={proposalData?.logo || ''} />
              </div>
            </Header>

            <HrHeader />

            <section>
              <b>Nº da proposta</b>
              <p>{proposalData?.id}</p>

              <br />

              <ContainerBetween>
                <ColumnBetween width="50%">
                  <Row>
                    <RowLabel>Status da proposta:</RowLabel>
                    <RowText>{formatProposalStatus(proposalData?.status)}</RowText>
                  </Row>

                  <Row>
                    <RowLabel>Modalidade de contratação:</RowLabel>
                    <RowText>{translatePolicyModality(proposalData?.modality)}</RowText>
                  </Row>

                  {shouldDisplayModalityDiscountObservation && (
                    <Row>
                      <SectionSmall>
                        Será aplicado {proposalData?.modality_discount}% de desconto na{' '}
                        {FidelityDiscountLabelByModality[proposalData?.modality]} parcela da Apólice
                      </SectionSmall>
                    </Row>
                  )}

                  <Row>
                    <RowLabel>Data de envio da proposta:</RowLabel>
                    <RowText>{proposalData?.created_at ? moment(proposalData?.created_at).format('DD/MM/YYYY') : '-'}</RowText>
                  </Row>
                </ColumnBetween>
                <ColumnBetween width="50%">
                  <Row>
                    <RowLabel>Protocolo:</RowLabel>
                    <RowText>{proposalData?.protocol}</RowText>
                  </Row>
                </ColumnBetween>
              </ContainerBetween>

              <SectionSmall>
                A Aceitação da proposta está sujeita a análise e aprovação do risco pela seguradora e o inicio de vigência somente
                ocorre após a aceitação do risco e do respectivo pagamento da parcela do seguro
              </SectionSmall>
              <SectionSmall>Esta proposta expira em 5 (cinco) dias caso não seja realizada a vistoria no veículo</SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DO SEGURADO</SectionTitle>
              </SectionContainer>

              <Column>
                <b>Nome do segurado:</b>
                <p>{proposalData?.user?.name?.toUpperCase()}</p>
              </Column>

              <ContainerBetween>
                <ColumnBetween width="30%">
                  <b>CPF:</b>
                  <p>{formatCPF(proposalData?.user?.cpf)}</p>
                </ColumnBetween>
                <ColumnBetween width="45%">
                  <b>Data de nascimento:</b>
                  <p>{moment(proposalData?.user?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</p>
                </ColumnBetween>

                <ColumnBetween width="25%">
                  <b>Sexo:</b>
                  <p>{proposalData?.user?.gender === 1 ? 'Masculino' : 'Feminino'}</p>
                </ColumnBetween>
              </ContainerBetween>

              <ContainerBetween>
                <ColumnBetween width="50%">
                  <b>E-mail:</b>
                  <p>{proposalData?.user?.email}</p>
                </ColumnBetween>

                <ColumnBetween width="50%">
                  <b>Telefone:</b>
                  <p>{formatPhone(proposalData?.user?.phone)}</p>
                </ColumnBetween>
              </ContainerBetween>

              <ContainerBetween>
                <Row>
                  <RowLabel>Logradouro:</RowLabel>
                  <RowText>{proposalData?.address?.street}</RowText>
                </Row>
              </ContainerBetween>

              <ContainerBetween>
                <RowBetween gap="6px" width="25%">
                  <b>Número:</b>
                  <p>{proposalData?.address?.number}</p>
                </RowBetween>
                <RowBetween gap="6px" width="25%">
                  <b>Bairro:</b>
                  <p>{proposalData?.address?.district}</p>
                </RowBetween>
                <RowBetween gap="6px" width="25%">
                  <b>Cidade/UF:</b>
                  <p>
                    {proposalData?.address?.city} / {proposalData?.address?.state}
                  </p>
                </RowBetween>
                <RowBetween gap="6px" width="25%">
                  <b>CEP:</b>
                  <p>{proposalData?.address?.zip_code}</p>
                </RowBetween>
              </ContainerBetween>
            </section>
            <section>
              <SectionContainer>
                <SectionTitle>CONDUTOR PRINCIPAL</SectionTitle>
              </SectionContainer>

              <Column>
                <b>Nome do condutor:</b>
                <p>{proposalData?.maindriver_name || proposalData?.user?.name}</p>
              </Column>

              <ContainerBetween>
                <ColumnBetween width="50%">
                  <b>CPF:</b>
                  <p>{formatCPF(proposalData?.maindriver_document || proposalData?.user?.cpf)}</p>
                </ColumnBetween>

                {proposalData?.proposalData?.maindriver_birthdate && (
                  <ColumnBetween width="50%">
                    <b>Data de nascimento:</b>
                    <p>
                      {proposalData?.proposalData?.maindriver_birthdate
                        ? moment(proposalData?.proposalData?.maindriver_birthdate).utcOffset(0).format('DD/MM/YYYY')
                        : ''}
                    </p>
                  </ColumnBetween>
                )}
              </ContainerBetween>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DO VEÍCULO</SectionTitle>
              </SectionContainer>

              <Row>
                <RowLabel>Nome do condutor:</RowLabel>
                <RowText>{proposalData?.maindriver_name || proposalData?.user?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Marca:</RowLabel>
                <RowText>{proposalData?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Modelo:</RowLabel>
                <RowText>{proposalData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Cor:</RowLabel>
                <RowText>{proposalData?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Placa:</RowLabel>
                <RowText>
                  {proposalData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(proposalData?.vehicle?.plate)}
                </RowText>
              </Row>
              <Row>
                <RowLabel>Ano fabricação/modelo:</RowLabel>
                <RowText>
                  {proposalData?.vehicle?.manufacture_year}/{proposalData?.vehicle?.year_model}
                </RowText>
              </Row>
              <Row>
                <RowLabel>Chassi:</RowLabel>
                <RowText>{proposalData?.vehicle?.chassi}</RowText>
              </Row>
              <Row>
                <RowLabel>Valor*:</RowLabel>
                <RowText>{proposalData?.vehicle?.value && formatCurrency(proposalData?.vehicle?.value)}</RowText>
              </Row>
              <Row>
                <RowLabel>Código Fipe:</RowLabel>
                <RowText>{proposalData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code}</RowText>
              </Row>
              <Row>
                <RowLabel>Veículo de Leilão:</RowLabel>
                <RowText>{proposalData?.vehicle?.auction || proposalData?.vehicle?.auction === 1 ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>Uso do veículo:</RowLabel>
                <RowText>{proposalData?.vehicle?.vehicle_use?.name || '--'}</RowText>
              </Row>
              <Row>
                <RowLabel>Chassis Remarcado/Veiculo Recuperado de Sinistro:</RowLabel>
                <RowText>{!!proposalData?.vehicle?.remarked ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>0km:</RowLabel>
                <RowText>{!!proposalData?.vehicle?.zero_km ? 'Sim' : 'Não'}</RowText>
              </Row>
              <div class="page-break" />
              <Row>
                <RowLabel>Possui GNV:</RowLabel>
                <RowText>{!!proposalData?.vehicle?.gnv ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>Região de Circulação:</RowLabel>
                <RowText>
                  {proposalData?.address?.city} / {proposalData?.address?.state}
                </RowText>
              </Row>

              <SectionSmall>* Valor da Tabela Fipe do veículo na data da contratação</SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DA PROPOSTA</SectionTitle>
              </SectionContainer>

              <Row>
                <RowLabel>Modalidade de Contratação:</RowLabel>
                <RowText> VMR - Valor de Mercado Referenciado</RowText>
              </Row>

              <Row>
                <RowLabel>Fator de Ajuste LMI: </RowLabel>
                <RowText>{proposalData?.policyData?.adjustment_factor || proposalData?.policyData?.adjustment || 100}%*</RowText>
              </Row>

              <Column>
                <b>LIMITE MÁXIMO DE INDENIZAÇÃO (LMI):</b>
                <p>
                  Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida pelo SEGURADO(A) e expresso
                  na proposta.
                </p>
              </Column>

              <Row>
                <RowLabel>PEÇAS E REPAROS:</RowLabel>
                <RowText>
                  UTILIZAÇÃO DE PEÇAS (NOVAS, USADAS, ORIGINAIS OU NÃO), desde que mantenham a especificação técnica do
                  fabricante.
                </RowText>
              </Row>

              {hasCoverages && (
                <>
                  <SectionContainer>
                    <SectionTitle>COBERTURAS</SectionTitle>
                  </SectionContainer>
                  <table>
                    <thead>
                      <tr>
                        <td width={300}>Cobertura</td>
                        <td width={200}>LMI **</td>
                        <td width={150}>Prêmio</td>
                        <td width={250}>Franquias</td>
                      </tr>
                    </thead>
                    <tbody>
                      {proposalData?.arrayUnique
                        ? proposalData?.arrayUnique
                            .filter((item) => item.isService === false)
                            .map((product) => (
                              <tr>
                                <td>{product?.name}</td>
                                <td>{formatCurrency(product?.lmi || '--')}</td>
                                <td>{formatCurrency(product?.value || '--')}</td>
                                <td>{product?.deductible || '--'}</td>
                              </tr>
                            ))
                        : ''}
                    </tbody>
                  </table>
                </>
              )}
              {hasServices && (
                <>
                  <SectionContainer>
                    <SectionTitle>SERVIÇOS</SectionTitle>
                  </SectionContainer>
                  <table>
                    <thead>
                      <tr>
                        <td width={300}>Serviço</td>
                        <td width={200}>LMI **</td>
                        <td width={150}>Valor</td>
                        <td width={250}>Franquias</td>
                      </tr>
                    </thead>
                    <tbody>
                      {proposalData?.arrayUnique
                        ? proposalData?.arrayUnique
                            .filter((item) => item.isService === true)
                            .map((product) => (
                              <tr>
                                <td>{product?.name}</td>
                                <td>{formatCurrency(product?.lmi || '--')}</td>
                                <td>{formatCurrency(product?.value || '--')}</td>
                                <td>{product?.deductible || '--'}</td>
                              </tr>
                            ))
                        : ''}
                    </tbody>
                  </table>
                </>
              )}

              <SectionSmall>
                Em caso de indenização integral ou roubo e furto total, será aplicada a franquia regressiva estabelecida nas
                condições gerais da apolice.
              </SectionSmall>

              <br />

              <SectionSmall>* Se selecionada a opção de leilão, o fator de ajuste deverá ser de 70%</SectionSmall>
              <SectionSmall>** Limite máximo de indenização</SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>VALOR DA PROPOSTA</SectionTitle>
              </SectionContainer>

              {hasCoverages && (
                <Row>
                  <RowLabel>Prêmio Líquido Coberturas:</RowLabel>
                  <RowText>
                    {formatCurrency((proposalData?.isHinova ? proposalData?.valueFinal : valueCoverage) - proposalData?.iof)}
                  </RowText>
                </Row>
              )}

              <Row>
                <RowLabel>IOF:</RowLabel>
                <RowText>{formatCurrency(proposalData?.iof || 0)}</RowText>
              </Row>

              {hasCoverages && (
                <Row>
                  <RowLabel>Prêmio Total Coberturas:</RowLabel>
                  <RowText>{formatCurrency(proposalData?.valueFinal - valueService)}</RowText>
                </Row>
              )}

              {hasServices && (
                <Row>
                  <RowLabel>Custo Serviços:</RowLabel>
                  <RowText> {formatCurrency(valueService)}</RowText>
                </Row>
              )}

              <Row>
                <RowLabel>Valor Total Seguro:</RowLabel>
                <RowText>{formatCurrency(proposalData?.valueFinal || 0)}</RowText>
              </Row>
            </section>

            <div class="page-break" />

            <section style={{ borderBottom: 'none' }}>
              <SectionContainer>
                <SectionTitle>RESUMO DAS COBERTURAS</SectionTitle>
              </SectionContainer>

              <b>Canais de Atendimento</b>
              <h4>SAC: {proposalData?.company?.phone_sac}</h4>
              <h4>Email: {proposalData?.company?.email}</h4>
              <h4>Assistência 24h: {proposalData?.company?.phone_support}</h4>

              <br />

              <h2>A íntegra das condições gerais deste produto, podem ser consultadas no portal www.splitrisk.com.br</h2>

              <h2>
                É fundamental que o segurado tenha conhecimento das cláusulas: 'Cláusula de riscos excluídos', ‘Perdas de
                Direitos', 'Obrigações do Segurado' e 'Cláusula de participação proporcional ou franquia', constantes nas
                condições gerais.
              </h2>

              {proposalData?.arrayUnique
                ? proposalData?.arrayUnique.map((product, index) => (
                    <>
                      {index === 3 && <div class="page-break" />}
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              <>
                <span className="generalConditionsTitle">Valor determinado:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É a modalidade de seguro, que garante ao Segurado, no caso de indenização integral, roubo ou furto do veículo, o
                  pagamento de quantia fixa, em moeda corrente nacional, conforme o LMI-Limite Máximo de Indenização e Fator de
                  Ajuste estabelecidos no ato da contratação e independentemente do valor de mercado do veículo quando da
                  ocorrência do sinistro.
                </p>
              </>

              <>
                <span className="generalConditionsTitle">Fator de ajuste:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É o percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que será
                  aplicado sobre o valor que constar no LMI-Limite Máximo de Indenização de cotação para o veículo, para a
                  estipulação do valor da indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar
                  em valor inferior àquele valor do LMI-Limite Máximo de Indenização estabelecido na proposta, de acordo com as
                  características do veículo e seu estado de conservação.
                </p>
              </>
            </section>

            <section>
              <SectionSmall style={{ marginTop: 30 }}>
                O Seguro Auto é um produto da Split Risk Seguradora regularizada na SUSEP, pelo projeto Sandbox, no processo
                15414.618377/2020-87: https://www.gov.br/susep/pt-br/assuntos/sandbox-regulatorio/sandbox-regulatorio-1a-edicao
              </SectionSmall>
            </section>
          </Printable>
        </Container>
      )}

      <Footer>
        <FooterRow>
          <FooterText>
            <p>
              Proposta emitida por: <b>{proposalData?.findPartnerAssistance?.social_reason}</b> | CNPJ:{' '}
              {proposalData?.findPartnerAssistance?.cnpj}{' '}
            </p>
          </FooterText>
          <LogoIcon>
            <img src={proposalData?.logo || ''} alt="Logo parceiro" />
          </LogoIcon>
        </FooterRow>
      </Footer>
    </>
  );
}

export default BackofficePrintQuotation;
