/* eslint-disable react-hooks/exhaustive-deps */
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import SideBar from './SideBar';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';
import CommissionSlider from './CommissionSlider';
import { Select } from 'components';
import { useEffect, useState } from 'react';
import { Container } from '../../style';
import { Content, OptionsSide, VehicleDataSide, CoveragesArea, ExclusiveLabel } from './style';
import { formatFipeId } from 'utils/vehicleType';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Alert } from '@material-ui/lab';

export const CoverageStep = ({
  quotationData,
  setQuotationData,
  loadingQuotation,
  quotationResponse,
  nextStep,
  percentCommission,
  setPercentCommission,
  percentDiscount,
  setPercentDiscount,
  sliderHandle,
  setSliderHandle,
  dataCoverage,
  setDataCoverage,
  proposalResponse,
  handleQuotation,
}) => {
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [exclusive, setExclusive] = useState(false);
  const [basicCoverages, setBasicCoverages] = useState([]);
  const [policyModalitiesOptions, setPolicyModalitiesOptions] = useState([{ value: 'monthly', label: 'Mensal' }]);

  const [supportCoverages, setSupportCoverages] = useState([]);
  const [replacementCoverages, setReplacementCoverages] = useState([]);
  const [glassCoverages, setGlassCoverages] = useState([]);
  const [optionalCoverages, setOptionalCoverages] = useState([]);
  const [lmiCoverages, setLmiCoverages] = useState([]);
  const [deductibleCoverages, setDeductibleCoverages] = useState([]);
  const [toogleSections, setToogleSections] = useState({
    basic: true,
    additional: true,
    optional: true,
    discount: true,
    modality: true,
  });
  const getOptionalCoverages = async (exclusive) => {
    const resp = await api({
      method: 'post',
      url: `/quotation/get-optional-coverage`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      data: {
        type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
        value: quotationData?.vehicle?.value,
        zero_km: quotationData?.vehicle?.zero_km,
        exclusive,
      },
    });
    if (exclusive || quotationData?.vehicle?.zero_km) {
      setDataCoverage((prev) => ({
        ...prev,
        optional_coverages: {
          ...prev?.optional_coverages,
          workshop: undefined,
        },
      }));
    }
    setOptionalCoverages(resp?.data);
  };
  const getDeductibleCoverages = async (exclusive) => {
    const resp = await api({
      method: 'post',
      url: `/quotation/get-deductible-coverage`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      data: {
        type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
        value: quotationData?.vehicle?.value,
        zero_km: quotationData?.vehicle?.zero_km,
        exclusive,
      },
    });
    if (exclusive) {
      setDataCoverage((prev) => ({
        ...prev,
        id_deductible_coverage: undefined,
      }));
    }
    setDeductibleCoverages(resp?.data);
  };

  const handleOptionalAndBasic = async (value) => {
    setDataCoverage((prev) => ({
      ...prev,
      id_basic_coverage: value === 'Selecione' ? undefined : Number(value),
    }));

    for (const i of basicCoverages) {
      if (i.exclusive && Number(value) === i.id) {
        setExclusive(true);
        await getOptionalCoverages(true);
        await getDeductibleCoverages(true);
        break;
      } else {
        setExclusive(false);
        await getOptionalCoverages(false);
        await getDeductibleCoverages(false);
      }
    }
  };
  const loadData = async () => {
    setLoading(true);
    try {
      const promises = [
        api({
          method: 'post',
          url: `/quotation/get-basic-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            brand: quotationData?.vehicle?.id_brand,
            fipe_code: quotationData?.vehicle?.fipe_code,
            value: quotationData?.vehicle?.value,
          },
        }),
        api({
          method: 'post',
          url: `/quotation/get-support-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
            value: quotationData?.vehicle?.value,
          },
        }),
        api({
          method: 'post',
          url: `/quotation/get-replacement-car-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
            value: quotationData?.vehicle?.value,
          },
        }),
        api({
          method: 'post',
          url: `/quotation/get-glass-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
            value: quotationData?.vehicle?.value,
          },
        }),
        api({
          method: 'post',
          url: `/quotation/get-optional-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
            value: quotationData?.vehicle?.value,
            zero_km: quotationData?.vehicle?.zero_km,
            exclusive: false,
          },
        }),
        api({
          method: 'post',
          url: `/quotation/get-fipe-lmi-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
            value: quotationData?.vehicle?.value,
            from_auction: quotationData?.vehicle?.auction || quotationData?.vehicle?.remarked,
          },
        }),
        api({
          method: 'post',
          url: `/quotation/get-deductible-coverage`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          data: {
            type_vehicle: formatFipeId(quotationData?.vehicle?.id_type || quotationData?.vehicle?.id_vehicle_type),
            value: quotationData?.vehicle?.value,
            exclusive: false,
          },
        }),
      ];
      let filteredObject = undefined;
      if (quotationData?.quotationProposalData?.quotationProposalId) {
        const quotation = await api({
          method: 'get',
          url: `/quotation/proposal/${quotationData?.quotationProposalData?.quotationProposalId}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
        });
        const response = quotation.data;
        const optionals = response?.TB_PRICING_OPTIONAL_COVERAGE_TB_QUOTATION_PROPOSAL.map((i) => {
          return i.id_pricing_optional_coverage;
        });

        const coverageData = {
          id_basic_coverage: response?.TB_PRICING_BASIC_COVERAGE_TB_QUOTATION_PROPOSAL[0]?.id_pricing_basic_coverage,
          id_support_coverage: response?.TB_PRICING_SUPPORT_TB_QUOTATION_PROPOSAL[0]?.id_pricing_support,
          id_replacement_car_coverage: response?.TB_PRICING_REPLACEMENT_CAR_TB_QUOTATION_PROPOSAL[0]?.id_pricing_replacement_car,
          id_glass_coverage: response?.TB_PRICING_GLASS_TB_QUOTATION_PROPOSAL[0]?.id_pricing_glass,
          id_fipe_lmi_coverage: response?.TB_PRICING_FIPE_LMI_TB_QUOTATION_PROPOSAL[0]?.id_pricing_fipe_lmi,
          id_deductible_coverage: response?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL[0]?.id_pricing_deductible,
          optionals,
          discount: response.discount_percent,
          commission: response.grievance_percent,
        };
        filteredObject = Object.fromEntries(Object.entries(coverageData).filter(([key, value]) => value !== undefined));

        setDataCoverage(filteredObject);
      }
      const responses = await Promise.all(promises);

      const [basicResp, supportResp, replacementResp, glassResp, optionalResp, lmiResp, deductibleResp] = responses;
      // console.log(filteredObject, 'iiii');
      for (const item of basicResp?.data) {
        if (item.id === filteredObject?.id_basic_coverage && item.exclusive) {
          setExclusive(true);
          continue;
        }
      }
      setBasicCoverages(basicResp?.data);
      setSupportCoverages(supportResp?.data);
      setReplacementCoverages(replacementResp?.data);
      setGlassCoverages(glassResp?.data);
      setOptionalCoverages(optionalResp?.data);
      setLmiCoverages(lmiResp?.data);
      setDeductibleCoverages(deductibleResp?.data);

      const damagesToThirdParties = optionalResp.data?.filter((i) => i.type === 'damages_to_third_parties');
      const app = optionalResp.data?.filter((i) => i.type === 'app');
      const workshop = optionalResp.data?.filter((i) => i.type === 'workshop');
      const accessoriesCar = optionalResp.data?.filter((i) => i.type === 'accessories_car');
      const accessoriesMotorcycle = optionalResp.data?.filter((i) => i.type === 'accessories_motorcycle');
      const gasKit = optionalResp.data?.filter((i) => i.type === 'gas_kit');
      const extraordinaryEexpenses = optionalResp.data?.filter((i) => i.type === 'extraordinary_expenses');
      if (filteredObject) {
        for (const item of damagesToThirdParties) {
          if (filteredObject?.optionals?.includes(item.id)) {
            setDataCoverage((prev) => ({
              ...prev,
              optional_coverages: { ...prev?.optional_coverages, damages_to_third_parties: item.id },
            }));
            break;
          }
        }
        for (const item of app) {
          if (filteredObject?.optionals?.includes(item.id)) {
            setDataCoverage((prev) => ({
              ...prev,
              optional_coverages: { ...prev?.optional_coverages, app: item.id },
            }));
            break;
          }
        }
        for (const item of workshop) {
          if (filteredObject?.optionals?.includes(item.id)) {
            setDataCoverage((prev) => ({
              ...prev,
              optional_coverages: { ...prev?.optional_coverages, workshop: item.id },
            }));
            break;
          }
        }
        for (const item of accessoriesCar) {
          if (filteredObject?.optionals?.includes(item.id)) {
            setDataCoverage((prev) => ({
              ...prev,
              optional_coverages: { ...prev?.optional_coverages, accessories_car: item.id },
            }));
            break;
          }
        }
        for (const item of accessoriesMotorcycle) {
          if (filteredObject?.optionals?.includes(item.id)) {
            setDataCoverage((prev) => ({
              ...prev,
              optional_coverages: { ...prev?.optional_coverages, accessoriesMotorcycle: item.id },
            }));
            break;
          }
        }
        // for (const item of gasKit) {
        //   if (filteredObject?.optionals?.includes(item.id)) {
        //     setDataCoverage((prev) => ({
        //       ...prev,
        //       optional_coverages: { ...prev?.optional_coverages, gas_kit: item.id },
        //     }));
        //     break;
        //   }
        // }
        for (const item of extraordinaryEexpenses) {
          if (filteredObject?.optionals?.includes(item.id)) {
            setDataCoverage((prev) => ({
              ...prev,
              optional_coverages: { ...prev?.optional_coverages, extraordinary_expenses: item.id },
            }));
            break;
          }
        }
      }

      const slider = await api({
        method: 'get',
        url: 'quotation/commission',
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      setSliderHandle({ ...slider.data });

      if (!slider.data.handle) {
        setPercentCommission(100);
      } else {
        setPercentCommission(
          dataCoverage.commission
            ? (dataCoverage.commission * 100) / slider.data.grievance_percent
            : slider.data.start_slider === 'max'
              ? 100
              : 0,
        );
      }
      //dont change, dont make comments too, i'm wrong
      // if (!slider.data.handle) {
      //   setPercentCommission(100);
      // } else {
      //   setPercentCommission(dataCoverage.commission ? (dataCoverage.commission * 100) / slider.data.grievance_percent : 0);
      //   setPercentDiscount(dataCoverage.discount ? (dataCoverage.discount * 100) / slider.data.discount_percent : 0);
      // }
      setLoading(false);
      setHasChange(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    console.log(dataCoverage);
  };

  const getAvailablePolicyModalities = async () => {
    try {
      const resp = await api({
        method: 'GET',
        url: `quotation/get-modality-options/${quotationData?.quotationProposalData?.quotationProposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      if (resp.data.length > 0) {
        setPolicyModalitiesOptions(resp.data);
        if (resp.data.length === 1) {
          setQuotationData({ ...quotationData, policy_modality: resp.data[0].value });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadData();
    setHasChange(false);
  }, []);
  useEffect(() => {
    handleOptionalAndBasic(dataCoverage?.id_basic_coverage);
    getAvailablePolicyModalities();
  }, [basicCoverages]);
  useEffect(() => {
    setHasChange(true);
  }, [dataCoverage, percentDiscount, percentCommission, quotationData.policy_modality]);

  return (
    <Container>
      {/* <h1>{`${hasChange}`}</h1> */}
      <Content>
        {loading && <ReactLoading color="rgb(0 176 90)" height={24} width={24} type="spin" />}
        {!loading && (
          <OptionsSide>
            <h3 style={{ marginTop: 15 }}>
              Coberturas básicas
              <IconButton
                size="small"
                style={{ marginLeft: 10 }}
                onClick={() => setToogleSections({ ...toogleSections, basic: !toogleSections.basic })}
              >
                {toogleSections?.basic ? <BsChevronUp size={18} /> : <BsChevronDown size={18} />}
              </IconButton>
            </h3>
            {toogleSections?.basic && (
              <>
                <CoveragesArea>
                  <Select
                    disableLabel
                    height="35px"
                    width="400px"
                    // value={quotationData?.id_basic_coverage}
                    value={dataCoverage?.id_basic_coverage}
                    onChange={(e) => {
                      handleOptionalAndBasic(e.target.value);
                    }}
                  >
                    <option>Selecione</option>
                    {basicCoverages?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </Select>
                </CoveragesArea>
                {exclusive && <ExclusiveLabel>Coberturas EXCLUSIVAS possuem franquias quando acionadas</ExclusiveLabel>}
              </>
            )}

            {dataCoverage?.id_basic_coverage ? (
              <>
                {/* MODALIDADE DE CONTRATAÇÃO */}
                <h3 style={{ marginTop: 15 }}>
                  Modalidade de contratação
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => setToogleSections({ ...toogleSections, modality: !toogleSections.modality })}
                  >
                    {toogleSections?.modality ? <BsChevronUp size={18} /> : <BsChevronDown size={18} />}
                  </IconButton>
                </h3>

                {toogleSections?.modality && (
                  <CoveragesArea style={{ marginTop: -10 }}>
                    <Select
                      disableLabel
                      height="35px"
                      width="400px"
                      disabled={policyModalitiesOptions?.length <= 1}
                      value={quotationData?.policy_modality}
                      onChange={(e) => {
                        setQuotationData({ ...quotationData, policy_modality: e.target.value });
                        handleOptionalAndBasic(dataCoverage?.id_basic_coverage);
                      }}
                    >
                      {policyModalitiesOptions?.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.label}
                        </option>
                      ))}
                    </Select>
                  </CoveragesArea>
                )}

                <h3 style={{ marginTop: 15 }}>
                  Coberturas adicionais
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => setToogleSections({ ...toogleSections, additional: !toogleSections.additional })}
                  >
                    {toogleSections?.additional ? <BsChevronUp size={18} /> : <BsChevronDown size={18} />}
                  </IconButton>
                </h3>

                {toogleSections?.additional && (
                  <CoveragesArea style={{ marginTop: -10 }}>
                    {/* ASSIST 24h */}
                    {supportCoverages?.length > 0 && (
                      <Select
                        label="Assistência 24h"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.id_support_coverage}
                        onChange={(e) => {
                          // setQuotationData({ ...quotationData, id_support_coverage: e.target.value === "Selecione"?undefined:Number(e.target.value) });
                          setDataCoverage((prev) => ({
                            ...prev,
                            id_support_coverage: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {supportCoverages?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {/* CARRO RESERVA */}
                    {replacementCoverages?.length > 0 && (
                      <Select
                        label="Carro reserva"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.id_replacement_car_coverage}
                        onChange={(e) => {
                          // setQuotationData({ ...quotationData, id_replacement_car_coverage: e.target.value === "Selecione"?undefined:Number(e.target.value) });
                          setDataCoverage((prev) => ({
                            ...prev,
                            id_replacement_car_coverage: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {replacementCoverages?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {/* VIDROS */}
                    {glassCoverages?.length > 0 && (
                      <Select
                        label="Vidros"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.id_glass_coverage}
                        onChange={(e) => {
                          // setQuotationData({ ...quotationData, id_glass_coverage: e.target.value === "Selecione"?undefined:Number(e.target.value) });
                          setDataCoverage((prev) => ({
                            ...prev,
                            id_glass_coverage: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {glassCoverages?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {/* LMI */}
                    {lmiCoverages?.length > 0 && (
                      <Select
                        label="Limite máximo de indenização (LMI). Obs: Padrão LMI 100%"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.id_fipe_lmi_coverage}
                        onChange={(e) => {
                          // setQuotationData({ ...quotationData, id_fipe_lmi_coverage: e.target.value === "Selecione"?undefined:Number(e.target.value) });
                          setDataCoverage((prev) => ({
                            ...prev,
                            id_fipe_lmi_coverage: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {lmiCoverages?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {/* FRANQUIA REDUZIDA */}
                    {deductibleCoverages?.length > 0 && (
                      <Select
                        label="Franquia Reduzida. Obs: Padrão normal 10%"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.id_deductible_coverage}
                        onChange={(e) => {
                          // setQuotationData({ ...quotationData, id_deductible_coverage: e.target.value === "Selecione"?undefined:Number(e.target.value) });
                          setDataCoverage((prev) => ({
                            ...prev,
                            id_deductible_coverage: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {deductibleCoverages?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </CoveragesArea>
                )}

                <h3 style={{ marginTop: 15 }}>
                  Coberturas opcionais
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => setToogleSections({ ...toogleSections, optional: !toogleSections.optional })}
                  >
                    {toogleSections?.optional ? <BsChevronUp size={18} /> : <BsChevronDown size={18} />}
                  </IconButton>
                </h3>
                {toogleSections?.optional && (
                  <CoveragesArea style={{ marginTop: -10 }}>
                    {/* DANOS A TERCEIROS - damages_to_third_parties */}
                    {optionalCoverages?.filter((i) => i.type === 'damages_to_third_parties')?.length > 0 && (
                      <Select
                        label="Danos a terceiros"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.optional_coverages?.damages_to_third_parties}
                        onChange={(e) => {
                          // setQuotationData({
                          //   ...quotationData,
                          //   optionalCoverages: {
                          //     ...quotationData?.optionalCoverages,
                          //     damages_to_third_parties: Number(e.target.value === "Selecione"?undefined:Number(e.target.value)),
                          //   },
                          // });
                          setDataCoverage((prev) => ({
                            ...prev,
                            optional_coverages: {
                              ...prev?.optional_coverages,
                              damages_to_third_parties: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                            },
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {optionalCoverages
                          ?.filter((i) => i.type === 'damages_to_third_parties')
                          ?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Select>
                    )}

                    {/* APP - app */}
                    {optionalCoverages?.filter((i) => i.type === 'app')?.length > 0 && (
                      <Select
                        label="APP"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.optional_coverages?.app}
                        onChange={(e) => {
                          // setQuotationData({
                          //   ...quotationData,
                          //   optionalCoverages: {
                          //     ...quotationData?.optionalCoverages,
                          //     app: Number(e.target.value === "Selecione"?undefined:Number(e.target.value)),
                          //   },
                          // });
                          setDataCoverage((prev) => ({
                            ...prev,
                            optional_coverages: {
                              ...prev?.optional_coverages,
                              app: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                            },
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {optionalCoverages
                          ?.filter((i) => i.type === 'app')
                          ?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Select>
                    )}

                    {/* Livre Escolha Oficina - workshop */}
                    {optionalCoverages?.filter((i) => i.type === 'workshop')?.length > 0 && (
                      <Select
                        label="Oficina"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.optional_coverages?.workshop}
                        onChange={(e) => {
                          // setQuotationData({
                          //   ...quotationData,
                          //   optionalCoverages: {
                          //     ...quotationData?.optionalCoverages,
                          //     workshop: Number(e.target.value === "Selecione"?undefined:Number(e.target.value)),
                          //   },
                          // });
                          setDataCoverage((prev) => ({
                            ...prev,
                            optional_coverages: {
                              ...prev?.optional_coverages,
                              workshop: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                            },
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {optionalCoverages
                          ?.filter((i) => i.type === 'workshop')
                          ?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Select>
                    )}

                    {/* Acessórios - accessories_car */}
                    {optionalCoverages?.filter((i) => i.type === 'accessories_car')?.length > 0 && (
                      <Select
                        label="Acessórios"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.optional_coverages?.accessories_car}
                        onChange={(e) => {
                          // setQuotationData({
                          //   ...quotationData,
                          //   optionalCoverages: {
                          //     ...quotationData?.optionalCoverages,
                          //     accessories_car: Number(e.target.value === "Selecione"?undefined:Number(e.target.value)),
                          //   },
                          // });
                          setDataCoverage((prev) => ({
                            ...prev,
                            optional_coverages: {
                              ...prev?.optional_coverages,
                              accessories_car: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                            },
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {optionalCoverages
                          ?.filter((i) => i.type === 'accessories_car')
                          ?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Select>
                    )}

                    {/* Kit Gas - gas_kit */}
                    {optionalCoverages?.filter((i) => i.type === 'gas_kit')?.length > 0 && quotationData?.vehicle.gnv && (
                      <Select
                        label="Kit Gás"
                        height="35px"
                        width="400px"
                        disabled={true}
                        value={dataCoverage?.optional_coverages?.gas_kit}
                        onChange={(e) => {
                          // setQuotationData({
                          //   ...quotationData,
                          //   optionalCoverages: {
                          //     ...quotationData?.optionalCoverages,
                          //     gas_kit: Number(e.target.value === "Selecione"?undefined:Number(e.target.value)),
                          //   },
                          // });
                        }}
                      >
                        {/* <option>Selecione</option> */}
                        {optionalCoverages
                          ?.filter((i) => i.type === 'gas_kit')
                          ?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Select>
                    )}

                    {/* Depesas Extraordinárias - extraordinary_expenses */}
                    {optionalCoverages?.filter((i) => i.type === 'extraordinary_expenses')?.length > 0 && (
                      <Select
                        label="Depesas Extraordinárias"
                        height="35px"
                        width="400px"
                        value={dataCoverage?.optional_coverages?.extraordinary_expenses}
                        onChange={(e) => {
                          // setQuotationData({
                          //   ...quotationData,
                          //   optionalCoverages: {
                          //     ...quotationData?.optionalCoverages,
                          //     extraordinary_expenses: Number(e.target.value === "Selecione"?undefined:Number(e.target.value)),
                          //   },
                          // });
                          setDataCoverage((prev) => ({
                            ...prev,
                            optional_coverages: {
                              ...prev?.optional_coverages,
                              extraordinary_expenses: e.target.value === 'Selecione' ? undefined : Number(e.target.value),
                            },
                          }));
                        }}
                      >
                        <option>Selecione</option>
                        {optionalCoverages
                          ?.filter((i) => i.type === 'extraordinary_expenses')
                          ?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Select>
                    )}
                  </CoveragesArea>
                )}
                {sliderHandle.handle && (
                  <>
                    <h3 style={{ marginTop: 25 }}>
                      Desconto e agravo
                      <IconButton
                        size="small"
                        style={{ marginLeft: 10 }}
                        onClick={() => setToogleSections({ ...toogleSections, discount: !toogleSections.discount })}
                      >
                        {toogleSections?.discount ? <BsChevronUp size={18} /> : <BsChevronDown size={18} />}
                      </IconButton>
                    </h3>
                    {percentCommission === 0 && toogleSections.discount && (
                      <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                        <Alert severity="warning">ATENÇÃO: Zerar o slider resultará em comissão zero para esta venda.</Alert>
                      </div>
                    )}

                    {toogleSections?.discount && (
                      <CoveragesArea style={{ marginTop: -10 }}>
                        <CommissionSlider
                          grievance_percent={sliderHandle.grievance_percent}
                          setPercentCommission={setPercentCommission}
                          percentCommission={
                            dataCoverage.commission > 0 && dataCoverage.discount > 0
                              ? (dataCoverage.commission * 100) / sliderHandle.grievance_percent
                              : percentCommission
                          }
                        />
                      </CoveragesArea>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </OptionsSide>
        )}

        {!loading && (
          <VehicleDataSide>
            <SideBar
              type="quotation"
              confirmButtonHandleClick={() => {
                handleSubmit();
                // nextStep();
              }}
              nextStep={() => nextStep()}
              hasChange={hasChange}
              setQuotationData={setQuotationData}
              sliderHandle={sliderHandle?.handle}
              setHasChange={setHasChange}
              quotationData={quotationData}
              dataCoverage={dataCoverage}
              quotationResponse={quotationResponse}
              percentCommission={percentCommission}
              percentDiscount={percentDiscount}
              grievance_percent={sliderHandle.grievance_percent}
              discount_percent={sliderHandle.discount_percent}
              haveAddress={false}
              haveCoverages={false}
              confirmButtonCollor="#fe9501"
              confirmButtonBoxShadowColor="#ff9500"
              proposalResponse={proposalResponse}
            />
          </VehicleDataSide>
        )}
      </Content>
    </Container>
  );
};

export default CoverageStep;
